// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "g_h";
export var breadcrumbContainer = "g_j";
export var breadcrumb = "g_k";
export var PostComponent = "g_l";
export var titlePostRelated = "g_m";
export var postReadMore = "g_n";
export var articleMain = "g_p";
export var articleSidebarLeft = "g_q";
export var articleSidebarRight = "g_r";
export var articleColumnMain = "g_s";